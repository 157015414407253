// surcharge des class bootstrap

/* =========  reprise des couleurs des boutons de base ici
*/

//$primary:       $blue;
//$secondary:     $gray-600;
//$success:       $green;
//$info:          $cyan;
//$warning:       $yellow;
//$danger:        $red;
//$light:         $gray-100;
//$dark:          $gray-800;

/* =========  mise en forme des nav
*/

/* =========  mise en forme des forms
*/

.form-control{
  font-size: map-get($font-sizes,16);
}

/* =========  mise en forme des boutons bootstrap
*/

.btn{

}

.btn-primary{

}

.btn-secondary{

}

.btn-success{

}

.btn-info{

}

.btn-warning{

}

.btn-danger{

}

.btn-light{
  //@include button-variant(background, border, text-color, background hover, border-hover);
  @include button-variant($light, $light, $dark, darken($primary, 15%), darken($primary, 15%), $white);
}

.btn-dark{

}

.list-group{
  .list-group-item{
    background: none;
  }
}

.alert {
  color: $white;
}

.form-check {
  margin-top: 15px;
}