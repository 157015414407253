.btn-pink {
  @include button-variant($rose, $white);
}

.btn-pink-color {
  color: $rose;
  padding: 0;

  &:hover {
    text-decoration: underline;
    color: $rose;

  }
}