/* ======= font size
*/

/* Pour faire l'appel de la font désiré :
  map-get($font-size,10);
 */

$font-sizes: ();

// renseigner à partir de combien lancer la boucle
$font-size-debut: 10;

// jusqu'à combien terminer la boucle
$font-size-fin: 110;

@for $i from $font-size-debut through $font-size-fin {
  $font-sizes: map-merge(
    (
      $i: ($i/10)+rem
    ),
    $font-sizes
  );
}