/* =======

  css à placer sur les balises html générale avant de rentrer dans le détail du dossier 5.layout et 6.blocs

======= */

html {
  font-size: .625rem; // 1rem = 10px with this line (easier calcul)
}

body {
  @include font($font-02, map-get($font-sizes, 16), $font-weight-light, $gris);
  background-color: $gris-clair;
}

.body {
  overflow: hidden;
}

sup {
  font-size: 60%;
}

strong {
  font-weight: $font-weight-bold;
}

.invalid {
  color: $red;
  font-weight: bold;
}

input.invalid {
  border: 1px solid $red;
}

.text-underline-hover {
  &:hover {
    text-decoration: underline;
  }
}

a {
  text-decoration: none;
}

.play {
  height: 80px;
  width: 80px;
  background-color: $primary;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 42%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meavedette-info {
  width: 630px;
  border-radius: 0 12px 0 12px;
  @include media-breakpoint-down(lg){
    width: 480px;
  }
  @include media-breakpoint-down(md){
    width: 100%;
  }
}

::-moz-selection { /* Code for Firefox */
  color: $selection-color;
  background: $selection-background;
}

::selection {
  color: $selection-color;
  background: $selection-background;
}

ins {
  img {
    @include media-breakpoint-down(md) {
      width: 100%;
      object-fit: contain;
    }
  }
}