// Tous les types de titre par défaut

h1{
  @include font($font-01, map-get($font-sizes,28), $font-weight-bold, $gris);
}

h2{
  @include font($font-01, map-get($font-sizes,26), $font-weight-bold, $gris);
}

h3{
  @include font($font-02, map-get($font-sizes,14), $font-weight-semi-bold, $gris);
}

h4{
  @include font($font-01, map-get($font-sizes,17), $font-weight-bold, $gris);
}

.h1{
  @include font($font-01, map-get($font-sizes,28), $font-weight-bold, $gris);
}

.h2{
  @include font($font-01, map-get($font-sizes,18), $font-weight-bold, $gris);
}

.h3{
  @include font($font-02, map-get($font-sizes,16), $font-weight-bold, $gris);
}

.h4{
  @include font($font-02, map-get($font-sizes,14), $font-weight-semi-bold, $gris);
}