@font-face {
	font-family: "icons";
	src: url('../fonts/icons.eot');
	src: url('../fonts/icons.eot?#iefix') format('eot'),
		url('../fonts/icons.woff2') format('woff2'),
		url('../fonts/icons.woff') format('woff'),
		url('../fonts/icons.ttf') format('truetype'),
		url('../fonts/icons.svg#icons') format('svg');
}

.ico:before {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.ico-actu:before {
	content: "\E001";
}

.ico-actualites-wave:before {
	content: "\E002";
}

.ico-arrow-dropdown-menu:before {
	content: "\E003";
}

.ico-arrow-right:before {
	content: "\E004";
}

.ico-download:before {
	content: "\E005";
}

.ico-facebook:before {
	content: "\E006";
}

.ico-ico-heures:before {
	content: "\E007";
}

.ico-instagram:before {
	content: "\E008";
}

.ico-jeux:before {
	content: "\E009";
}

.ico-logo-skazy:before {
	content: "\E00A";
}

.ico-logo:before {
	content: "\E00B";
}

.ico-pinterest:before {
	content: "\E00C";
}

.ico-programmetv:before {
	content: "\E00D";
}

.ico-sortir:before {
	content: "\E00E";
}

.ico-straeming:before {
	content: "\E00F";
}

.ico-twitter:before {
	content: "\E010";
}

.ico-user:before {
	content: "\E011";
}

.ico-vague:before {
	content: "\E012";
}

.ico-youtube:before {
	content: "\E013";
}
