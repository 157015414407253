// card amélioré
.card-body {
  .program-intro {
    .overlay {
      transition: all 0.3s;
    }

    &:hover {
      .overlay {
        opacity: 1 !important;
      }
    }
  }
}


.alias-les-kids,
.alias-netflix,
.alias-amazone-prime-video {
  .auto-promo {

  }
}