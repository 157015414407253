// backgroudn personnalisés

.bg-donuts{
  background-image: url(../images/donut.svg);
  background-repeat: no-repeat;
  background-position: top right;
}

.bg-wave{
  mask:url(../images/bg-actu.svg) no-repeat left top;
  -webkit-mask:url(../images/bg-actu.svg) no-repeat left top;
  mask-size: 100%;
}

.bg-vague{
  mask:url(../images/vague.svg) no-repeat center bottom;
  -webkit-mask:url(../images/vague.svg) no-repeat center bottom;
  mask-size: 100%;
}