// élément qui prend toute la longueur du site, rajouter un .container dans l'élément qui a cette classe
.vw-full{
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
}

.vw-sm-full {
  @include media-breakpoint-down(md){
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw;
  }
}