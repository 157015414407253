// variables de bootstrap par défault
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #373736;
$gray-900: #212529;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #65afe2;

$rose: #C4006B;
$gris: #333333;
$gris1: #4F4F4F;
$gris2: #EDEDED;
$gris3: #828282;
$gris-clair: #F8F8F8;
$gris-footer: #F2F2F2;
$gris-hr: #E0E0E0;
$canalsat: darken($rose, 10%);
$gotv: $rose;
$tnt: lighten($rose, 15%);

$primary: $rose;
$secondary: $gris;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$body: $gris-clair;
$footer: $gris-footer;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "mobile":     $gray-300,
    "body":       $gris-clair,
    "corps":       $gris-clair,
    "footer":     $footer,
    "error":      $gray-300,
    "rose":      $rose,
    "gris":      $gris,
    "gris1":      $gris1,
    "gris2":      $gris2,
    "gris3":      $gris3,
    "gris4":      $gray-500,
    "canalsat":   $canalsat,
    "gotv":       $gotv,
    "tnt":        $tnt,
    "hr": $gris-hr
  ),
  $theme-colors
);

/* ======= uikit overlay couleur
*/

$overlay-primary-background: rgba($gray-700, 0.6);

/* ======= couleur des liens par défault
*/

$nav-link-color: $gray-800;
$nav-link-hover-color: $black;

/* ======= Couleur de séléction
*/

$selection-background: $primary;
$selection-color: $white;
