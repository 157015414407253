/* ======= fonts à déclarer
*/

$font-01: 'Chivo', sans-serif;
$font-02: 'Overpass', sans-serif;

$font-family-base: $font-01;

/* ======= fonts weight
*/
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;


$nav-pills-link-active-color: $primary !important;

/* ======= transitions
*/

$transition: .25s ease-in-out;

/* ======= dropdown
*/

$dropdown-min-width: 10rem;
$dropdown-border-radius: 0;
$dropdown-border-width: 0;
$dropdown-font-size: map-get($font-sizes, 16);

/* ======= border-radius
*/

$border-radius: 1.2rem;
$border-radius-sm: .6rem;
$border-radius-lg: 5rem;
$border-radius-pill: 50rem;

$radius: ();

$radius: (
        null: $border-radius,
        0: 0,
        1: $border-radius-sm,
        2: $border-radius,
        3: $border-radius-lg,
        circle:50%,
        pill: $border-radius-pill
);

$max-radius: 50;

@for $i from 2 through $max-radius {
  $radius: map-merge(
      (
              $i: ($i/10)+rem
      ),
      $radius
  );
}

/* ======= shadow générique
*/
$enable-shadows: true;
$box-shadow-sm: 0 .5rem 1rem rgba($black, .05);
$box-shadow: 0 1rem 2rem rgba($black, .05);
$box-shadow-lg: 0 2rem 4rem rgba($black, .15);

/* ======= card
*/

$card-spacer-y: 20px;
$card-spacer-x: 30px;
$card-border-width: 0;
$card-box-shadow: 0 1rem 4rem 0 rgba($black,0.05);

/* ======= spacers
*/

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer / 4,
        2: $spacer / 2,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        9: 9rem,
        144: 14.4rem,
        initial: initial,
);

// renseigner à partir de combien lancer la boucle
$spacing-debut: 10;

// jusqu'à combien terminer la boucle

$spacing-fin: 80;

// permet de générer toutes les class spacing avec le responsive
@for $i from $spacing-debut through $spacing-fin {
  $spacers: map-merge(
                  (
                          $i: ($i/10)+rem
                  ),
                  $spacers
  );
}

/* ======= les gutters
*/

$grid-gutter-width: 3rem;
$gutters: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        8: 8rem,
        30: 3rem,
);

/* ======= position absolute
*/

$position-values: (
        0: 0,
        10: 10%,
        15: 15%,
        20: 20%,
        25: 25%,
        40: 40%,
        50: 50%,
        90: 90%,
        100: 100%,
        492: 492px,
        n210:-210px,
        n240:-240px,
        n285:-285px
);

$positions: (static relative absolute fixed sticky);

/* ======= width
*/

.h-470 {
  height: 470px;
}

/* ======= z-index
*/

$zindexs: (1 2 3 4 5);

/* ======= width
*/

$widths: (
        20: 20%,
        25: 25%,
        50: 50%,
        70: 70%,
        75: 75%,
        80: 80%,
        90: 90%,
        100: 100%,
        auto: auto,
        26px:2.6rem,
        50px:5rem
);

/* ======= utilities de bootstrap
*/

$utilities: ();
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
    (
            "width": (
                    property: width,
                    class: w,
                    responsive:true,
                    values: $widths
            ),
            "position": (
                    property: position,
                    responsive:true,
                    values: $positions
            ),
            "z-index": (
                    property: z-index,
                    responsive: true,
                    values: $zindexs
            ),
            "font-size": (
                    rfs: true,
                    property: font-size,
                    responsive: true,
                    class: fs,
                    values: $font-sizes
            ),
            "line-height": (
                    property: line-height,
                    class: lh,
                    values: $spacers
            ),
            "rounded": (
                    property: border-radius,
                    class: rounded,
                    responsive: true,
                    values: ( $radius )
            ),
            "rounded-top": (
                    property: border-top-left-radius border-top-right-radius,
                    class: rounded-top,
                    responsive: true,
                    values: ( $radius )
            ),
            "rounded-end": (
                    property: border-top-right-radius border-bottom-right-radius,
                    class: rounded-end,
                    responsive: true,
                    values: ( $radius )
            ),
            "rounded-bottom": (
                    property: border-bottom-right-radius border-bottom-left-radius,
                    class: rounded-bottom,
                    responsive: true,
                    values: ( $radius )
            ),
            "rounded-start": (
                    property: border-bottom-left-radius border-top-left-radius,
                    class: rounded-start,
                    responsive: true,
                    values: ( $radius )
            ),
    ),
    $utilities
);

$alert-bg-scale: 0;
$alert-border-scale: 0;

/* =================== Taille des containers
 */

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1100px
);

/* =================== Nav
 */

$nav-link-padding-x: 2.5rem;
$nav-pills-border-radius: 0;
$nav-pills-link-active-color:       $primary;
$nav-pills-link-active-bg:          none;

/* =================== Btn
 */

$btn-padding-y: 13px;
$btn-padding-x: 35px;
$btn-font-family: $font-02;
$btn-font-size: map-get($font-sizes,16);
$btn-font-weight: $font-weight-semi-bold;
$btn-line-height: 0.3;

/* =================== select
 */

$form-select-padding-y:             1.3rem;
$form-select-padding-x:             2rem;
$form-select-font-family:           $font-02;
$form-select-font-size:             map-get($font-sizes,16);
$form-select-font-weight:           $font-weight-semi-bold;
$form-select-border-radius:       $border-radius;
$form-select-border-width:        0;
$form-select-indicator-color:       $primary;

/* =================== dropdown
 */

$dropdown-padding-y: 1rem;
$dropdown-min-width: 100%;
$dropdown-border-radius:            $border-radius;
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            $light;
$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           $primary;
$dropdown-inner-border-radius:      0;

/* =================== input
 */

$input-border-radius:                   0.5rem;
