.btn{
  &-back-to-top {
	bottom: 15%;
	right: 20px;
  }
}

.btn-jeu {
  color: #fff;
  background-color: #C4006B;
  border-color: #C4006B;
}

.btn-light {
  &:hover {
    background-color: darken($white, 15%) !important;
  }
}